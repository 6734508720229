import { AppFooter, AppNavbar } from "../../components";
import { StoreScreen1, StoreScreen2 } from "../../containers";
import "./Store.css";

const Store = () =>{

    return(
        <div className="page-store">
            <AppNavbar/>
            <StoreScreen1/>
            <StoreScreen2/>
            <AppFooter/>
        </div>
    )
};

export default Store;