import "./MusicsScreen2.css";
import globalStates from '../../utils/global';
import { useContext, useState} from "react";
import { artworkAchilles, artworkBranches, artworkClyde, artworkMany, artworkPetunia, artworkZero } from "../../assets";

const MusicsScreen2 = () =>{
    const context = globalStates && globalStates.globalContext;
    const globalContext:any = useContext(context);
    const windowWidthClass = globalContext && globalContext.windowWidthClass;

    const discographyList = [
        {
            imgSrc: artworkClyde,
            title: "Clyde",
            type: "Single", 
            link: "https://open.spotify.com/track/3OZOAAn6dQvRFUJTMpQvSI?si=0ab9e453162d46ea"
        },
        {
            imgSrc: artworkBranches,
            title: "Branches",
            type: "Single",
            link: "https://open.spotify.com/track/4gz1FCcU6TI5esCZCkLPai?si=cf8ce772aef7492a"
        },
        {
            imgSrc: artworkPetunia,
            title: "Petunia",
            type: "Album",
            link: "https://open.spotify.com/track/3UnR8Op2jDnvqxxYNYMuhy?si=a4ac9dc80b4f45b2"
        },
        {
            imgSrc: artworkZero,
            title: "Zero",
            type: "Single",
            link: "https://open.spotify.com/track/3W5lHMmOM3OU0Rz6JYFxvq?si=1664fbb921b042a6"
        },
        {
            imgSrc: artworkMany,
            title: "Many Were Found Dead",
            type: "Single",
            link: "https://open.spotify.com/track/73DVzdFoxHXGBS3V9xO1Ew?si=2ef03ad3d0fe4377"
        },
        {
            imgSrc: artworkAchilles,
            title: "Achilles",
            type: "Single",
            link: "https://open.spotify.com/track/2mzaFP81oRoqOqkomVg7uE?si=5819d09a44f045ef"
        },

    ]

    const [searchTerm, setSearchTerm] = useState('');
    const [filteredProducts, setFilteredProducts] = useState(discographyList);
    const handleSearch = (event:any) => {
        const term = event.target.value.toLowerCase();
        setSearchTerm(term);

        const filtered = discographyList.filter((product:any) => 
        product.title.toLowerCase().includes(term) ||
        product.type.toLowerCase().includes(term)
        );

        setFilteredProducts(filtered);
    };

    const openLinkHandler = (link:any) =>{
        // console.log("link", link)
        window.open(link, '_blank');
    }

    return(
        <div className={`${windowWidthClass}-musics-screen-2`}>
            <div className="screen-menubar">
                <input 
                    type="text" 
                    value={searchTerm}
                    onChange={handleSearch}
                    placeholder="search here"/>
            </div>
            <div className="screen-content">
                <div className="wrapper">
                    {
                        filteredProducts.map((music:any, index:any)=>{
                            const {imgSrc, title, type, link} = music;
                            return(
                                <div key={index+title} className="card">
                                        <div className="img">
                                            <img src={imgSrc} alt={title}
                                                onClick={()=>openLinkHandler(link)}
                                            />
                                        </div>
                                        <div className="texts">
                                            <h4>{title}</h4>
                                            <p>{type}</p>
                                        </div>
                                        
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
};

export default MusicsScreen2;