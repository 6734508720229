import { AppFooter, AppNavbar } from "../../components";
import { ArtistScreen1, ArtistScreen2 } from "../../containers";
import "./Artist.css";

const Artist = () =>{

    return(
        <div className="page-artist">
            <AppNavbar/>
            <ArtistScreen1/>
            <ArtistScreen2/>
            <AppFooter/>
        </div>
    )
};

export default Artist;