import { iconApple, iconSpotify, iconYoutube } from "../../assets";
import globalStates from '../../utils/global';
import { useContext } from "react";
import "./HomeScreen3.css";


const HomeScreen3 = () =>{
    const context = globalStates && globalStates.globalContext;
    const globalContext:any = useContext(context);
    const windowWidthClass = globalContext && globalContext.windowWidthClass;

    const artistSpotify = "https://open.spotify.com/artist/4zdSyyDm2DRSzAIzQdmRrm?si=dtwWRPJyTS6G0GKVok7MgQ";
    const artistApple = "https://music.apple.com/id/artist/2-am-strums/1520852952";
    const artistYoutube = "https://www.youtube.com/channel/UCKi5spnqCN19AtHMZAh1tVw";

    const openLinkHandler = (link:any) =>{
        // console.log("link", link)
        window.open(link, '_blank');
    }
    return (
        <div className={`${windowWidthClass}-home-screen-3`} >
            <div className="screen screen-title">
                <p>stream musics now</p>
            </div>
             <div className="screen screen-content">
                <div className="img-group">
                    <img src={iconSpotify} alt="green spotify logo" onClick={()=>openLinkHandler(artistSpotify)} />
                    <p>listen on spotify</p>
                </div>
                <div className="img-group">
                    <img src={iconYoutube} alt="red youtube icon"  onClick={()=>openLinkHandler(artistYoutube)}/>
                    <p>watch on youtube</p>
                </div>
                <div className="img-group">
                     <img src={iconApple} alt="white blue pink apple icon"  onClick={()=>openLinkHandler(artistApple)}/>
                     <p>play on apple music</p>
                </div>
            </div>
            {/* {windowWidthClass!=="w-mob"?<Screen3Accessory/>:null} */}
        </div>
    )
};

export default HomeScreen3;