import "./StoreScreen2.css";
import globalStates from '../../utils/global';
import { useContext } from "react";

const StoreScreen2 = () =>{
    const context = globalStates && globalStates.globalContext;
    const globalContext:any = useContext(context);
    const windowWidthClass = globalContext && globalContext.windowWidthClass;
    return(
        <div className={`${windowWidthClass}-store-screen-2`}>
            <h3>stay tune for available merch</h3>
        </div>
    )
};

export default StoreScreen2;